import { apiRoute } from "../utils";
import { BaseRequest } from "./Request";
const queryString = require("query-string");

export const getBlog = async (queryParams: any) => {
  const baseRequest = new BaseRequest();
  let url = "/blogs";
  url += "?" + queryString.stringify(queryParams);
  const response = (await baseRequest.get(url)) as any;
  return await response.json();
};

export const getFeeds = async () => {
  const baseRequest = new BaseRequest();
  let url = "/rss-feed";
  const response = (await baseRequest.get(url)) as any;
  console.log(response, "response");
  return await response?.json();
};

export const addBlogDetails = async (
  blogId: any,
  campaignId: any,
  category: any
) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/blog`);
  const response = (await baseRequest.post(url, {
    blog_title: blogId,
    campaign_id: campaignId,
    category,
  })) as any;
  return await response?.json();
};

export const updateBlog = async (
  blog: { blogTitle: any; campaignId: any; category: any },
  Id: number
) => {
  const baseRequest = new BaseRequest();
  let url = `admin/blog/${Id}`;
  const response = (await baseRequest.patch(url, {
    blog_title: blog.blogTitle,
    campaign_id: blog.campaignId,
    category: blog.category,
  })) as any;
  return await response?.json();
};

export const getBlogById = async (Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/blog-details/${Id}`);
  const response = (await baseRequest.get(url)) as any;
  return await response.json();
};

export const blogStatusUpdate = async (isActive: number, Id: number) => {
  const baseRequest = new BaseRequest();
  let url = `admin/blog-active/${Id}`;
  const response = (await baseRequest.patch(url, {
    is_active: isActive,
  })) as any;
  return await response?.json();
};

export const deleteBlogById = async (Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/delete-blog/${Id}`);
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};
