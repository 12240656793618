import React, { useEffect, useState } from "react";
import useStyles from "../style";
import FormControl from "@material-ui/core/FormControl";
import { Controller } from "react-hook-form";
import { renderErrorCreatePool } from "../../../utils/validate";
import { DatePicker, Switch } from "antd";
import { changeDisplayStatus, handleScheduleTime } from "../../../request/pool";
import { alertSuccess } from "../../../store/actions/alert";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";
import GenerateLink from "./GenerateLink";
import moment from "moment";
import { DATETIME_FORMAT, isScheduleTime } from "../../../constants";

function DisplayScheduleTime(props: any) {
  const classes = useStyles();
  const { setValue, errors, control, poolDetail, setPageReload, watch, isEditDisabled } = props;
  const renderError = renderErrorCreatePool;
  const dispatch = useDispatch();
  const [datePickerOpen, setDatePickerOpen] = useState(false); // State to control DatePicker

  useEffect(() => {
    if (poolDetail) {
      // Join Times
      if (poolDetail?.schedule_time) {
        setValue(
          "schedule_time",
          moment(poolDetail?.schedule_time, DATETIME_FORMAT)
        );
      }
    }
  }, [poolDetail, setValue]);

 

  const scheduleTime = async (value: any) => {
    const res = await handleScheduleTime({
      schedule_time: value,
      poolId:poolDetail?.id
    });
    console.log("Shedule timing: ", res);
    if (res.status === 200) {
      dispatch(alertSuccess("Schedule Timing setting successful!"));
     
    } 
    return value;
  };

  return (
    <>
       
      <div style={{ display: "flex", alignItems: "center",marginBottom:'10px'}}>
      
        <div className={classes.formControlFlexBlock}
        >
          <label className={classes.formControlLabel}>
          Pool Visibility Scheduled Time
          </label>
          <div style={{}}>
            <Controller
              control={control}
              name="schedule_time"
              render={(field ) => (
                <DatePicker
                  {...field}
                  open={datePickerOpen} 
                  format="YYYY-MM-DD HH:mm:ss"
                  // Disable past dates
                  disabledDate={(current:any) => {
                    return current && current < moment().startOf("day");
                  }}
                  // Disable past times when the selected date is today
                  disabledTime={(current:any) => {
                    if (current && current.isSame(moment() as any, "day")) {
                      const currentHour = moment().hour();
                      const currentMinute = moment().minute();

                        return {
                          disabledHours: () =>
                            Array.from(Array(24).keys()).filter((hour) => hour < currentHour),
                          disabledMinutes: (selectedHour:any) =>
                            selectedHour === currentHour
                              ? Array.from(Array(60).keys()).filter((minute) => minute < currentMinute)
                              : [],
                        };
                    }
                    return {};
                  }}
                  showTime={{
                    defaultValue: moment("00:00:00", "HH:mm:ss") as any,
                    format: "HH:mm",
                  }}
                  // @ts-ignore
                  defaultValue={
                    poolDetail?.schedule_time
                      ? moment(poolDetail.schedule_time)
                      : ""
                  }
                  // onChange={async(datetimeSelected:any) => {
                  //   if(datetimeSelected){

                  //     // setValue("schedule_time", datetimeSelected, {
                  //       //   shouldValidate: true,
                  //       // });
                  //      // eslint-disable-next-line no-restricted-globals
                  //      if (!confirm("Do you want schedule Pool Timing ?")) {
                  //        return false;
                  //       }
                  //       // await onChange(datetimeSelected);
                  //       await scheduleTime(datetimeSelected?.unix());
                  //       setDatePickerOpen(false)
                  //     }
                  // }}
                  onSelect={(datetimeSelected: any) => {
                    setValue(field.name, datetimeSelected);
                  }}
                
                  onOk={() => {
                    // Close the DatePicker when the user clicks OK
                    setDatePickerOpen(false);
                  }}
                  onOpenChange={(open) => {
                    setDatePickerOpen(open); // Update state when picker opens/closes
                  }}
                  minuteStep={15}
                />
              )}
            />
          </div>
          <div
            className={`${classes.formErrorMessage} ${classes.formErrorMessageAbsolute}`}
          >
            {renderError(errors, "schedule_time")}
          </div>
        </div>
      </div>
  
    </>
  );
}

export default withRouter(DisplayScheduleTime);
